function sliderFooter() {
    var swiper = new Swiper('.footer .swiper', {
        slidesPerView: '5',
        spaceBetween: 30,
        loop: true,
    })
}

function sliderMoreServices() {
    var swiper = new Swiper('.more-services .swiper', {
        slidesPerView: '3',
        spaceBetween: 20,
        autoplay: {
          delay: 3000
        },
        navigation: {
            nextEl: ".more-services .swiper-button-next",
            prevEl: ".more-services .swiper-button-prev",
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 3,
            },
        }
    })
}

$(document).ready(function() {
    sliderFooter();
    sliderMoreServices();

    // Menu
    $(".header-bottom__burger").click(function() {
        $(this).toggleClass("header-bottom__burger--active")
        $(".header-bottom__right").toggleClass("header-bottom__right--active")
        $("body").toggleClass("fixed-body")
    })

    // Scroll
    var header = $(".header-bottom");
    var scrollChange = 43;
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= scrollChange) {
            header.addClass("height");
        } else {
            header.removeClass("height");
        }
    });

    // Select
    $(".custom_select select").niceSelect()

    // services
    $(".services-main__top-btn").click(function() {
        $(".services-main__menu-desc").toggleClass("services-main__menu-desc--active")
        $(".services-main__menu").toggleClass("services-main__menu--active")
        $(".services-main__menu-close").toggleClass("services-main__menu-close--active")
        $("body").toggleClass("fixed-body")
    })

    // Modal
    $("[data-modal]").click(function () {
        let modalId = $(this).attr("data-modal")

        openModal(modalId)
    })

    function openModal(modalId){
        $(`#${modalId}`).addClass("modal--active")
        $("body").addClass("fixed-body")
    }

    $(".modal").click(function () {
        closeModal()
    })

    $("._close-modal").click(function () {
        closeModal()
    })

    $(".modal__dialog").click(function (e) {
        e.stopPropagation()
    })


    function closeModal() {
        $('.modal').removeClass("modal--active")
        $("body").removeClass("fixed-body")
    }

})